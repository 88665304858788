@import url("https://use.typekit.net/bgl3blu.css");

$red: #e97807;
$green: #24862f;
$bg: #000;
$sigFont: omnes-cond, sans-serif;

.meltit.site-content {
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffecc1' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.meltit {
  font-family: $sigFont;
  .cart.minimized {
    // background: url(http://mexcocinacafe.com/wp-content/uploads/2014/05/wood.jpg) no-repeat;
    padding: 0.4em 0.5em 0.1em 0.5em;
    // background-color: #ffffff;
    background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffecc1' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    // background-size: cover;
    border-bottom: none;

  }
  a.checkout-button.button {
    // font-family: 'Bungee Inline', sans-serif;
    background: $red;
    // font-weight: 400;
    box-shadow: none;
    // border: 2px solid #5a7e2a;
  }

  .category-name .text {
    position: relative;
    // text-transform: lowercase;
    font-family: $sigFont;
    font-weight: 400;
    font-size: 1.8em;
    background: none;
    color: black;
    font-weight: 600;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    // color: whtie;
    // text-shadow: 0px 1px 0 black;

  }
  // .category-name .text::before {
  //   content: " ";
  //   display: inline-block;
  //
  //   background: url(https://afag.imgix.net/mediterradean-grill/mgrill-icon.png?w=100) no-repeat;
  //   background-size: contain;
  //   // width: 28px;
  //   // height: 32px;
  //   // margin-right: -0.1em;
  //   width: 50px;
  //   height: 50px;
  //   // margin-right: -0.1em;
  //   position: absolute;
  //   left: -0.8em;
  //   top: -0.2em;
  // }
  .food-name {
    // text-transform: lowercase;
    // text-transform: capitalize;
  }
  .section-header {
    text-align: center;
    font-weight: 600;
    padding: 0.5em 0.8em;
    h2 {
      font-weight: 800;
      text-transform: uppercase;
      color: black;
    }
  }
  .splash {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 1em;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    h2 {
      color: white;
    }
    .catch-phrase {
      font-size: 2em;
    }
    @media screen and (max-width: 650px) {
      font-size: 1em;
    }
  }
  .landing-page, .top-banner {
    position: relative;
    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    position: relative;
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    background-color: white;
    // background: url(https://afag.imgix.net/melt-it-grilled-cheese/hero-2.png?auto=format) no-repeat;
    // background: url(https://afag.imgix.net/melt-it-grilled-cheese/hero-3.png?auto=format) no-repeat;
    // background: url(https://scontent-ort2-2.xx.fbcdn.net/v/t1.0-9/60328582_2054080218052919_3783572923114061824_n.jpg?_nc_cat=103&_nc_ht=scontent-ort2-2.xx&oh=e109a93534537a6473caf617dd63b2db&oe=5D9B9AB9) no-repeat;
    background: url(https://afag.imgix.net/melt-it-grilled-cheese/black-jack.jpg?w=1200&auto=format) no-repeat;
    background-size: cover;
    background-position: center 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  .landing-page .text {
    // font-family: Helvetica, sans-serif;
    font-weight: 600;
    color: white;
    text-shadow: 2px 2px 2px black;
    // background: rgba(0,0,0,0.3);
    padding: 0.5em 0.8em;
    text-align: center;

    h1 {
      font-weight: 600;
    }
    .location {
      .address {
        font-size: 0.6em;
      }
    }
  }
  .badge {
    // position: absolute;
    // text-shadow: 2px 2px 2px black;
    margin-left: -1.5em;
    width: 130px;
    // right: 1.5em;
    // bottom: -4em;
  }


  .pages-nav {
    font-family: $sigFont;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    // background: white;
    // color: black;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  $borderWidth: 4px;
  $paddingY: 0.35em;
  a.page-link {
    text-decoration: none;
    color: black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid white;
    &:hover, &.active {
      border-bottom: $borderWidth solid $red;
    }
  }

  a.order-online-link {
    font-size: 0.6em;
    text-transform: uppercase;
    // font-family: 'Helvetica', sans-serif;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 0.5em 0;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $red;
    position: relative;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .ordering-header-wrapper {
    background: url(https://afag.imgix.net/melt-it-grilled-cheese/swisted-1.jpg?w=800&auto=format) no-repeat;
    background-size: cover;
    background-position: center 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
    font-size: 1.5em;
    font-weight: 900;
    .text {
      padding: 1em;
      background: rgba(0,0,0,0.5);
    }
  }
  .landing-page-wrapper {
    position: relative;
  }
  .landing-page {
    // height: 540px;
  }
  .fire-bg {
    height: 100%;
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_1000/v1551995999/danstuff/grill-gif.gif) no-repeat;
    background-size: cover;
    background-position: center;

  }
  .video-bg {
    // filter: brightness(0.5);
    position: relative;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;

    background-size: cover;
  }
  video {
    display: block;

  }
  .video-container {

    width: 100%;
    // height: 100%;
    height: 540px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
    // max-width: 960px;
    overflow: hidden;
    // position: absolute;
    top: 0;
    right: 0;
  }
  .fire-gif {
    height: 540px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
  }
}
